import { ArrowBackIos } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid2,
  ImageList,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ClaimRowChip from "./ClaimRowChip";
import PDImageModal from "./PDImageModal";
import { useTheme } from "@emotion/react";

function PDListDisplayer({
  claim_id,
  tasks_info,
  num_valid_docs,
  date,
  setClosePDTasks,
  threshold_colors,
}) {

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down('xs'));
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  
    let currentBreakpointCols = 1; 
    if (isXl) currentBreakpointCols = 3;
    else if (isLg) currentBreakpointCols = 3;
    else if (isMd) currentBreakpointCols = 2;
    else if (isSm) currentBreakpointCols = 2;

  const [selectedThreshold, setselectedThreshold] = React.useState("93");
  return (
    <Box
      sx={{
        position: "fixed",
        // ml: { xs: "0px", ml: "175px" },
        // top: "50%",
        // left: "50%",
        // transform: "translate(-50%, -50%)",
        // overflow: "scroll",
        width: { xs: "100%", md: `calc(100% - 175px)` },
        bgcolor: "background.default",
        border: 0,
        borderRadius: 0,
        boxShadow: 10,
        p: 2,
        mt: 8,
      }}
    >
      <Box sx={{ p: 2, border: 5, borderColor: "lightgray", borderRadius: 3 }}>
        <Grid2 container>
          <Grid2 size="grow">
            <Button
              variant="contained"
              onClick={setClosePDTasks}
              sx={{
                borderRadius: 3,
                textTransform: "unset",
                backgroundColor: "secondary.main",
              }}
              startIcon={<ClearIcon />}
            >
              CLOSE
            </Button>
          </Grid2>
          <Grid2>
            <Stack spacing={1} sx={{ alignItems: "center" }}>
              <Stack direction={"row"}>
                <Typography
                  sx={{ py: 0, pr: 1, fontSize: 16, fontWeight: "light" }}
                >
                  PD Tasks for:
                </Typography>
                <Typography
                  sx={{ py: 0, pr: 1, fontSize: 16, fontWeight: "bold" }}
                >
                  {claim_id}
                </Typography>
                {/* <Typography sx={{fontSize: 16}}>Claim ID:</Typography> */}
              </Stack>
              <Stack
                sx={{ alignItems: "center" }}
                spacing={1}
                direction={"row"}
              >
                <Typography sx={{ py: 0, fontSize: 16, fontWeight: "bold" }}>
                  {date}
                </Typography>
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                <ClaimRowChip
                  text={"Valid Docs"}
                  value={num_valid_docs}
                  fontSize={10}
                  valueSize={14}
                  chip_width={"auto"}
                  chip_color={"#8fab87"}
                />
                {/* {num_valid_docs} Valid Doc IDs */}
                <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                {Object.entries(tasks_info).map(
                  ([threshold, tasks_list], index) => (
                    <Stack sx={{ alignItems: "center" }}>
                      <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                        0.{threshold}
                      </Typography>
                      <ClaimRowChip
                        text={"PD Tasks"}
                        value={tasks_list.length}
                        fontSize={10}
                        valueSize={14}
                        chip_width={"auto"}
                        chip_color={threshold_colors[index]}
                      />
                    </Stack>
                  )
                )}
              </Stack>
            </Stack>
          </Grid2>
          <Grid2 size="grow" />
        </Grid2>
        <Stack
          sx={{ pt: 1, alignItems: "center" }}
          spacing={1}
          direction={"row"}
        >
          <Typography> Pick Threshold: </Typography>
          <ButtonGroup
            variant="outlined"
            aria-label="Threshold choices"
            sx={{ borderColor: "secondary.main" }}
          >
            {Object.entries(tasks_info).map(
              ([threshold, tasks_list], index) => (
                <Button
                  onClick={() => setselectedThreshold(threshold)}
                  sx={{
                    bgcolor:
                      selectedThreshold === threshold
                        ? "secondary.main"
                        : "background.default",
                    color:
                      selectedThreshold === threshold
                        ? "white"
                        : "secondary.main",

                    borderColor: "secondary.main",
                  }}
                >
                  0.{threshold}
                </Button>
              )
            )}
          </ButtonGroup>
        </Stack>
        <Divider sx={{ py: 0.5, width: "100%", color: "gray" }} >
                Showing {tasks_info[selectedThreshold].length} PD Tasks --- Threshold=0.{selectedThreshold}
            </Divider>
      
        <Box sx={{ height: "500px", overflowY: 'auto', mt: 2 }}>
        <ImageList cols={currentBreakpointCols} sx={{overflow: "auto"}}>
          {tasks_info[selectedThreshold].map((task, task_index) => (
            <PDImageModal
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))}
           {/* {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))}
           {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))}
           {tasks_info[selectedThreshold].concat(tasks_info[selectedThreshold]).map((task, task_index) => (
            <PDImage
              task_index={task_index}
              img1={task[0][0]}
              img2={task[0][1]}
              num_pages={task.length}
            />
          ))} */}
          
        </ImageList>
        </Box>
      </Box>
    </Box>
  );
}

export default PDListDisplayer;
