import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import fire_json_95 from "../data/fire_95.json";
import fire_json_93_large from "../data/fire-results-093.json";
import fire_json_93_all from "../data/fire-results-all.json";
import Sorter from "../components/Sorter";
import ResultsList from "../OLD/ResultsListOLD";

const FireListPage = () => {
  const [results, setResults] = React.useState(fire_json_93_all.results);

  return (
    <Box sx={{ p: 2 }}>
      <Stack
        sx={{
          //display: "flex",
          alignItems: "center",
          //   justifyContent: "center",
        }}
        direction={"row"}
        spacing={2}
      >
        <Typography sx={{ fontSize: 24 }}>Fire Results</Typography>
        <Typography sx={{ fontSize: 16 }}>
          {results.length} Claims Found
        </Typography>
        <Sorter results={results} setResults={setResults} thresholds={fire_json_93_all.thresholds} />
      </Stack>

      <ResultsList data={results} />
    </Box>
  );
};

export default FireListPage;
