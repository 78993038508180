import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@mui/material";
import logo from "../images/SF_Logo_Red.svg";
import { blue, red } from "@mui/material/colors";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { useMsal } from "@azure/msal-react";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DrawerButton from "./DrawerButton";

const headerColor = "#282c34";

function Header({ header_height, drawer_width }) {
  const location = useLocation();
  const { accounts } = useMsal();
  const alias = accounts[0]["username"].split(".")[2].split("@")[0];

  // NEEDED FOR APP DRAWER
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <Box sx={{ height: "100%", bgcolor: "background.default", borderRight:0.5,  }}>
      <Toolbar />
      <Divider />
      <List sx={{ pt: { xs: 2, sm: 1 }, px: 0.5 }}>
        <DrawerButton
          closeDrawer={handleDrawerClose}
          key={"auto"}
          icon={<DirectionsCarIcon />}
          text={"Auto Results"}
          path_to={"/"}
        />
        <DrawerButton
        closeDrawer={handleDrawerClose}
          key={"fire"}
          icon={<LocalFireDepartmentIcon />}
          text={"Fire Results"}
          path_to={"/fire"}
        />
        <DrawerButton
          closeDrawer={handleDrawerClose}
          key={"summary"}
          icon={<StackedBarChartIcon />}
          text={"Summary"}
          path_to={"/summary"}
        />
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 0 }}>
      <AppBar
        elevation={1}
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          height: header_height,
          background: headerColor,
          // width: { sm: `calc(100% - ${drawer_width}px)` },
          // ml: { sm: `${drawer_width}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 1, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ marginRight: "auto", width: { xs: 120, md: 160 } }}>
            <img src={logo} alt="State Farm logo" />
          </Box>
          <Typography
            // variant="h6"
            component="div"
            sx={{
              fontSize: { xs: 14, sm: 16, md: 20 },
              flexGrow: 1,
              marginLeft: "10px",
            }}
          >
            Similarity Comparison Simulation
          </Typography>
          <Avatar
            sx={{
              width: { xs: 32, sm: 36, md: 40 },
              height: { xs: 32, sm: 36, md: 40 },
            }}
            alt="CV"
            src={`https://my.sfcollab.org/User%20Photos/Profile%20Pictures/OPR_${alias}_LThumb.jpg`}
          />
        </Toolbar>
        {/* <Box sx={{pl:2}}>
          <HeaderButton icon={<DirectionsCarIcon />} path={'/'} text={'Auto Results'}/>
          <HeaderButton icon={<LocalFireDepartmentIcon />} path={'/fire'} text={'Fire Results'}/> */}
        {/* <Button startIcon={<DirectionsCarIcon />} component={Link} to='/' variant={location.pathname==='/' ? "contained": "outlined"} sx={{borderTopRightRadius:0, borderBottomRightRadius:0}}>Auto</Button>
        <Button startIcon={<LocalFireDepartmentIcon />} component={Link} to='/fire'variant={location.pathname==='/fire' ? "contained": "outlined"} sx={{borderTopLeftRadius:0, borderBottomLeftRadius:0}}>Fire</Button> */}
        {/* </Box> */}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawer_width } }}
        aria-label="drawer"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          // container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawer_width,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawer_width,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      {/* <Box
        sx={{
          p: 1,
          border: 0,
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Button
          color="secondary"
          startIcon={<DirectionsCarIcon />}
          component={Link}
          to="/"
          variant={location.pathname === "/" ? "contained" : "outlined"}
          sx={{
            width: 150,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          Auto Results
        </Button>
        <Button
          color="secondary"
          startIcon={<LocalFireDepartmentIcon />}
          component={Link}
          to="/fire"
          variant={location.pathname === "/fire" ? "contained" : "outlined"}
          sx={{ width: 150, borderRadius: 0 }}
        >
          Fire Results
        </Button>
        <Button
          color="secondary"
          startIcon={<StackedBarChartIcon />}
          component={Link}
          to="/summary"
          variant={location.pathname === "/summary" ? "contained" : "outlined"}
          sx={{ width: 150, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          Summary
        </Button>
      </Box> */}
    </Box>
  );
}

export default Header;
