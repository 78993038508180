import { Box, Button, Menu, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import * as React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function Sorter({ results, setResults, thresholds }) {
  const [sortedBy, setSortedBy] = React.useState("id");
  const [ascending, setAscending] = React.useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  function handleSort(field, asc) {
    setAscending(asc);
    setSortedBy(field);
    handleMenuClose();
  }

  React.useEffect(() => {
    const sortedResults = [...results]; // Create a new array to avoid mutating the original array
    if (sortedBy === "docs") {
      sortedResults.sort((first, second) => {
        return ascending
          ? first.total_docs - second.total_docs
          : second.total_docs - first.total_docs;
      });
    } else if (sortedBy.startsWith("tasks")) {
      const sorted_thresh = sortedBy.slice(-3);
      sortedResults.sort((first, second) => {
        return ascending
          ? first.total_tasks[sorted_thresh] - second.total_tasks[sorted_thresh]
          : second.total_tasks[sorted_thresh] - first.total_tasks[sorted_thresh];
      });
    } else {
      sortedResults.sort((first, second) => {
        return ascending
          ? first.claim_id.localeCompare(second.claim_id)
          : second.claim_id.localeCompare(first.claim_id);
      });
    }
    setResults(sortedResults); // Update state with the new sorted array
  }, [sortedBy, ascending]); // <-- here put the parameter to listen, react will re-render component when your state will be changed

  const highlightColor = "#cdcdcd";
  return (
    <Box>
      <Button
        sx={{ width: 235 }}
        onClick={handleMenuClick}
        endIcon={ascending ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        variant="contained"
      >
        Sorted By: {sortedBy.startsWith("tasks") ? "PD Tasks (" + sortedBy.slice(-3).substring(0, 1) + "." + sortedBy.slice(-3).substring(1) + ")" :sortedBy}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={10}
      >
        <Stack>
          <Stack direction={"row"} sx={{ borderBottom: 1, borderTop: 1 }}>
            <MenuItem
              sx={{
                width: "50%",
                backgroundColor:
                  sortedBy === "id"
                    ? ascending
                      ? highlightColor
                      : "white"
                    : "white",
              }}
              onClick={() => handleSort("id", true)}
            >
              <ArrowUpwardIcon size="small" sx={{ width: 20, p: 0 }} /> ID
            </MenuItem>
            <MenuItem
              sx={{
                width: "50%",
                backgroundColor:
                  sortedBy === "id"
                    ? !ascending
                      ? highlightColor
                      : "white"
                    : "white",
              }}
              onClick={() => handleSort("id", false)}
            >
              <ArrowDownwardIcon size="small" sx={{ width: 20, p: 0 }} /> ID
            </MenuItem>
          </Stack>
          <Stack direction={"row"} sx={{ borderBottom: 1 }}>
            <MenuItem
              sx={{
                width: "50%",
                backgroundColor:
                  sortedBy === "docs"
                    ? ascending
                      ? highlightColor
                      : "white"
                    : "white",
              }}
              onClick={() => handleSort("docs", true)}
            >
              <ArrowUpwardIcon size="small" sx={{ width: 20, p: 0 }} /> # Docs
            </MenuItem>
            <MenuItem
              sx={{
                width: "50%",
                backgroundColor:
                  sortedBy === "docs"
                    ? !ascending
                      ? highlightColor
                      : "white"
                    : "white",
              }}
              onClick={() => handleSort("docs", false)}
            >
              <ArrowDownwardIcon size="small" sx={{ width: 20, p: 0 }} /> # Docs
            </MenuItem>
          </Stack>

          {thresholds.map((threshold, index) => (
            <Stack direction={"row"} sx={{ borderBottom: 1 }}>
              <MenuItem
                sx={{
                  width: "50%",
                  backgroundColor:
                    sortedBy === "tasks"+threshold
                      ? ascending
                        ? highlightColor
                        : "white"
                      : "white",
                }}
                onClick={() => handleSort("tasks"+threshold, true)}
              >
                <ArrowUpwardIcon size="small" sx={{ width: 20, p: 0 }} /> #
                PD Tasks ({threshold.substring(0, 1) + "." + threshold.substring(1)})
              </MenuItem>
              <MenuItem
                sx={{
                  width: "50%",
                  backgroundColor:
                    sortedBy === "tasks"+threshold
                      ? !ascending
                        ? highlightColor
                        : "white"
                      : "white",
                }}
                onClick={() => handleSort("tasks"+threshold, false)}
              >
                <ArrowDownwardIcon size="small" sx={{ width: 20, p: 0 }} /> #
                PD Tasks ({threshold.substring(0, 1) + "." + threshold.substring(1)})
              </MenuItem>
            </Stack>
          ))}
        </Stack>
      </Menu>
    </Box>
  );
}

export default Sorter;
