import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";
import TaskIcon from '@mui/icons-material/Task';

function ClaimRowChip({ text, value, id_color="#3c3c3c", chip_color="#bdbdbd", chip_width=100, fontSize=14, valueSize=16, third_row=null }) {
  
  function colorIsDarkAdvanced(bgColor) {
    let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return L <= 0.179;
  }

  return (
    <Box
      sx={{
        width: chip_width,
        borderRadius: 2,
        // mx: 2,
        py: 0.5,
        px: 1,
        bgcolor: chip_color,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Stack sx={{alignItems: "center"}}>
      <Typography sx={{fontSize:valueSize,  fontWeight: "bold", color: colorIsDarkAdvanced(chip_color) ? '#FFFFFF' : '#000000' }}>
        {value}
      </Typography>
         {/* <TaskIcon sx={{fontSize: 18, pr: 0.5, color: "darkgreen"}}/> */}
      <Typography sx={{fontSize:fontSize,  fontWeight: "light", color: colorIsDarkAdvanced(chip_color) ? '#FFFFFF' : '#000000' }}>
        {text}
      </Typography>
      {third_row ? third_row : null}
      </Stack>
     
    </Box>
  );
}

export default ClaimRowChip;
