import { Box, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

function PDImage({imgSrc1, imgSrc2, task_index}) {

    
   const [img1Data, setimg1Data] = useState(null);
   const [img2Data, setimg2Data] = useState(true);
   const [error, setError] = useState(null);
   const [loading, setLoading] = useState(true);


   useEffect(() => {
    fetch(
        "https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/results/pddisplay", 
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "imgSrc1": imgSrc1,
                "imgSrc2": imgSrc2
            }),
        })
      .then((response) => response.json())
      .then((data) => {
        setimg1Data(data.img1);
        setimg2Data(data.img2);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;


  return (

      <Stack sx={{border:0, alignItems: "center", justifyContent:"center"}} direction={"row"}>
        <Box
          component="img"
          sx={{
            maxWidth: "50%",
            maxHeight: 600,
            objectFit: "contain",
            cursor: "pointer",
            border: 1,
          }}
          alt="Img1"
          src={`data:image/png;base64, ${img1Data}`}
        />
        <Box
          component="img"
          sx={{
            maxHeight: 600,
            maxWidth: "50%",
            objectFit: "contain",
            cursor: "pointer",
            border: 1,
          }}
          alt="Img2"
          src={`data:image/png;base64, ${img2Data}`}
        />
      </Stack>

  )
}

export default PDImage