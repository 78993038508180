import {
  Box,
  Button,
  Divider,
  Grid2,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CustomChip from "../OLD/CustomChip";
import ClaimRowChip from "./ClaimRowChip";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Link } from "react-router-dom";
import ClaimRow from "./ClaimRow";
import { useNewMailMode } from "../NewMailModeContext";
import NewMailModeToggle from "./NewMailModeToggle";

const thresholdColors = [
  "#a9d6e5", //lightest blue
  "#89c2d9", //
  "#61a5c2",
  "#61a5c2",
  "#61a5c2",
];

function SimResultsList({ data, type_of_claim }) {
  
  

  const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();
  const thresholds = nm_to_all
    ? Object.entries(data[0].total_tasks.new_mail_to_all)
    : Object.entries(data[0].total_tasks.new_mail_to_new_mail);

  const columnsCount =
  2 + Object.keys(thresholds).length;

  // useEffect(() => {
  //   if (nm_to_all) {
  //     set_thresholds(Object.entries(data[0].total_tasks.new_mail_to_all));
  //   } else {
  //     set_thresholds(Object.entries(data[0].total_tasks.new_mail_to_new_mail));
  //   }
  // }, [nm_to_all, data]);

  
  return (
    <Box
      sx={{
        border: 0,
        borderRadius: 0,
        borderColor: "grey.500",
        // backgroundColor: "lightgrey",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <TableContainer
        sx={{
          border: 5,
          borderRadius: 3,
          borderColor: "lightgray",
          width: "100%",
        }}
      >
        <Table>
          <TableHead colSpan={columnsCount}>
            <TableRow colSpan={columnsCount}>
              <TableCell
                size="small"
                colSpan={columnsCount}
                align="center"
                sx={{ borderBottom: 0 }}
              >
                <Grid2 container>
                  <Grid2 size="grow" />
                  <Grid2>
                    <Stack>
                      <Typography sx={{ fontSize: 24 }}>
                        {type_of_claim} Results
                      </Typography>
                      <Typography>
                        3 Claims{data.length === 1 ? "" : "s"}
                      </Typography>
                    </Stack>
                  </Grid2>
                  <Grid2 size="grow" sx={{justifyContent: "right", display:"flex", alignItems: "right"}}>
                    <NewMailModeToggle />
                  </Grid2>
                </Grid2>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={columnsCount} sx={{ p: 0, border: 0 }}>
                <Divider sx={{ color: "gray", py: 0, width: "100%" }} >
                <Typography sx={{ fontSize: 14 }}>
                        {nm_to_all ? "New Mail to All" : "New Mail to New Mail"}
                      </Typography>
                  </Divider>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <ClaimRow
                hover={true}
                key={item.claim_id}
                item={item}
                columnsCount={columnsCount}
                nm_to_all={nm_to_all}
                handle_new_mail_mode_change={handle_new_mail_mode_change}
                thresholds={thresholds}
              />
            ))}
            {data.map((item) => (
              <ClaimRow
                hover={true}
                key={item.claim_id}
                item={item}
                columnsCount={columnsCount}
                nm_to_all={nm_to_all}
                handle_new_mail_mode_change={handle_new_mail_mode_change}
                thresholds={thresholds}
              />
            ))}
            {data.map((item) => (
              <ClaimRow
                hover={true}
                key={item.claim_id}
                item={item}
                columnsCount={columnsCount}
                nm_to_all={nm_to_all}
                handle_new_mail_mode_change={handle_new_mail_mode_change}
                thresholds={thresholds}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SimResultsList;
