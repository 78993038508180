import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ResultsDetails from "./ResultsDetails";

function ResultsList({ data }) {
  const id_color = "#3c3c3c";
  const chip_color = "#e1e1e1";
  return (
    <Box sx={{ py: 1, maxWidth: 600 }}>
      {data.results.map((item) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls={item.claim_id}
            id={item.claim_id}
            sx={{ justifyContent: "center", display: "flex", py: 0.5 }}
          >
            <Box sx={{ py: 0.5 }}>
              <Typography sx={{ fontWeight: "bold", color: id_color }}>
                {item.claim_id}
              </Typography>
            </Box>

            <Box sx={{  borderRadius: 5, mx: 2, py: 0.5, px: 1.5, bgcolor: chip_color  }}>
              <Typography sx={{ fontWeight: "light", color: id_color }}>
                Docs: {item.total_docs}
              </Typography>
            </Box>
            <Box sx={{  borderRadius: 5, mr: 2, py: 0.5, px: 1.5, bgcolor: chip_color  }}>
              <Typography sx={{ fontWeight: "light", color: id_color }}>
                Days: {item.total_days}
              </Typography>
            </Box>
            <Box sx={{  borderRadius: 5,mr: 2, py: 0.5, px: 1.5, bgcolor: chip_color  }}>
              <Typography sx={{ fontWeight: "light", color: id_color }}>
                Tasks: {item.total_tasks}
              </Typography>
            </Box>
          </AccordionSummary>
          
          <AccordionDetails>
            <ResultsDetails details={item.days}/>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

export default ResultsList;
