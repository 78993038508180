import * as React from "react";
import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TasksDisplayer from "./TasksDisplayer";

function ResultsDetails({ details, claim_id, threshold_colors }) {
  const id_color = "#3c3c3c";
  const chip_color = "#e1e1e1";

  const [openStates, setOpenStates] = React.useState(details.map(() => false));
  // const handleOpen = (index) => {
  //   setOpenStates(openStates.map((state, i) => (i === index ? true : state)));
  // };

  // const handleClose = (index) => {
  //   setOpenStates(openStates.map((state, i) => (i === index ? false : state)));
  // };

  const handleOpen = (index, index2) => {
    setOpenStates({ ...openStates, [`${index}-${index2}`]: true });
  };

  const handleClose = (index, index2) => {
    setOpenStates({ ...openStates, [`${index}-${index2}`]: false });
  };
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <Box>
      {details.map((item, index) => (
        <Stack
          key={index}
          direction={"row"}
          spacing={1}
          sx={{ alignItems: "center", py: 0.5 }}
        >
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: 75,
              fontWeight: "bold",
              color: id_color,
            }}
          >
            {item.date}
          </Typography>
          <ArrowForwardIcon sx={{ color: id_color }} />
          <Typography
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: 65,
              fontWeight: "light",
              color: id_color,
            }}
          >
            {item.docs} Docs
          </Typography>
          <ArrowForwardIcon sx={{ color: id_color }} />
          {Object.entries(item.tasks).map(([threshold, tasks_list], index2) => (
            <>
              <Button
                disabled={tasks_list.length === 0}
                sx={{
                  border: 1,
                  borderColor: id_color,
                  color: id_color,
                  width: 110,
                  backgroundColor: threshold_colors[threshold],
                }}
                variant="outlined"
                onClick={() => handleOpen(index, index2)}
              >
                {tasks_list.length} Tasks
              </Button>
              <Modal
                open={openStates[`${index}-${index2}`] || false}
                onClose={() => handleClose(index, index2)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <TasksDisplayer
                  claim_id={claim_id}
                  tasks_list={item.tasks[threshold]}
                  num_docs={item.docs}
                  date={item.date}
                  setCloseTasks={() => handleClose(index, index2)}
                  threshold={threshold}
                  thresh_color={threshold_colors[threshold]}
                />
              </Modal>
            </>
          ))}
          {/* <Button disabled={item.tasks.length===0}  sx={{ width: 110 }} variant="outlined" onClick={() => handleOpen(index)}>
            {item.tasks.length} Tasks
          </Button> */}
        </Stack>
      ))}
    </Box>
  );
}

export default ResultsDetails;
