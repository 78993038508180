import * as React from "react";
import { Box, Typography } from "@mui/material";

function CustomChip({ text, id_color="#3c3c3c", chip_color="#e1e1e1", chip_width=100, fontSize=15 }) {
  
  function colorIsDarkAdvanced(bgColor) {
    let color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    let r = parseInt(color.substring(0, 2), 16); // hexToR
    let g = parseInt(color.substring(2, 4), 16); // hexToG
    let b = parseInt(color.substring(4, 6), 16); // hexToB
    let uicolors = [r / 255, g / 255, b / 255];
    let c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    let L = (0.2126 * c[0]) + (0.7152 * c[1]) + (0.0722 * c[2]);
    return L <= 0.179;
  }

  return (
    <Box
      sx={{
        width: chip_width,
        borderRadius: 5,
        // mx: 2,
        py: 0.5,
        px: 1.5,
        bgcolor: chip_color,
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}
    >
      <Typography sx={{fontSize:fontSize,  fontWeight: "light", color: colorIsDarkAdvanced(chip_color) ? '#FFFFFF' : '#000000' }}>
        {text}
      </Typography>
    </Box>
  );
}

export default CustomChip;
