import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  Grid,
  Grid2,
  Grow,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ClaimRow from "../components/ClaimRow";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClaimDetailsByDay from "../components/ClaimDetailsByDay";
import { useNewMailMode } from "../NewMailModeContext";
import NewMailModeToggle from "../components/NewMailModeToggle";

function DetailsPage() {
  const { claim_id } = useParams(); // Extract claim_id from the route parameters
  const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();

  const location = useLocation();
  const state = location.state || {}; // Provide a default empty object if location.state is null
  const { item } = state;
  // Check if location.state exists and has item
  // const item = location.state ? location.state : null;
  if (!item) {
    // Handle the case where item is not available
    return (
      <Box>
        <Typography variant="h6">No Claim Details Available</Typography>
      </Box>
    );
  }
  

  // const thresholds = Object.entries(item.total_tasks.new_mail_to_new_mail);
  const thresholds = nm_to_all
    ? Object.entries(item.total_tasks.new_mail_to_all)
    : Object.entries(item.total_tasks.new_mail_to_new_mail);

  const columnsCount =
  2 + Object.keys(thresholds).length;
  return (
    <Grow in={item}>
      <Box sx={{ p: 2, border: 0 }}>
        <TableContainer
          sx={{
            border: 5,
            borderRadius: 3,
            borderColor: "lightgray",
            width: "100%",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  size="small"
                  colSpan={columnsCount}
                  align="center"
                  sx={{ borderBottom: 0, pt: 2 }}
                >
                  <Grid2 container spacing={1} sx={{ border: 0 }}>
                    <Grid2
                      size="grow"
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        component={Link}
                        to="/"
                        variant="contained"
                        sx={{
                          borderRadius: 3,
                          textTransform: "unset",
                          backgroundColor: "secondary.main",
                        }}
                        startIcon={<ArrowBackIosIcon />}
                      >
                        BACK
                      </Button>
                    </Grid2>
                    <Grid2 sx={{ display: "flex", alignItems: "center" }}>
                      <Typography sx={{ fontSize: 24 }}>
                        Details View
                      </Typography>
                    </Grid2>
                    <Grid2 size="grow" sx={{justifyContent: "right", display:"flex", alignItems: "right"}}>
                  
                      <NewMailModeToggle/>
                    </Grid2>
                  </Grid2>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={columnsCount} sx={{ p: 0, border: 0 }}>
                  <Divider sx={{ py: 0, width: "100%", color: "gray" }}>
                    <Stack direction={"row"}>
                      <Typography sx={{ fontSize: 16 }}>Claim ID:</Typography>
                      <Typography
                        sx={{
                          py: 0,
                          px: 0.5,
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        {claim_id}
                      </Typography>
                      <Typography sx={{textTransform: "capitalize", pr:0.5, fontSize: 16 }}>
                        --- { item.type_of_claim} 
                      </Typography>
                      <Typography sx={{ fontSize: 16 }}>
                        --- {nm_to_all ? "New Mail to All" : "New Mail to New Mail"}
                      </Typography>
                    </Stack>
                  </Divider>
                </TableCell>
              </TableRow>
            </TableHead>
            <ClaimRow
              columnsCount={columnsCount}
              item={item}
              dividerText={"Details by day"}
              nm_to_all={nm_to_all}
            />
          </Table>

          <ClaimDetailsByDay
            details_by_day={item.day_info}
            thresholds={thresholds}
            claim_id={claim_id}
            nm_to_all={nm_to_all}
          />
        </TableContainer>
      </Box>
    </Grow>
  );
}

export default DetailsPage;
