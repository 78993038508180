import {
  Divider,
  Grid2,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import ClaimRowChip from "./ClaimRowChip";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { Link } from "react-router-dom";
import { useNewMailMode } from "../NewMailModeContext";

const thresholdColors = [
  "#b6d8e3",
  "#a9d6e5", //lightest blue
  "#89c2d9", //
  "#61a5c2",
  "#468faf",
  "#2c7da0"
];


function ClaimRow(props) {
  const { item, columnsCount, dividerText, hover } = props;
  const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();

  const [open, setOpen] = React.useState(false);
  const tCellWith = 100;

  const current_total_docs =
    item.num_valid_doc_ids +
    item.num_fcm_excluded +
    item.num_exact_dup_excluded;

  const thresholds = nm_to_all
    ? Object.entries(item.total_tasks.new_mail_to_all)
    : Object.entries(item.total_tasks.new_mail_to_new_mail);
  return (
    <React.Fragment>
      <TableRow
        hover={hover}
        component={Link}
        // onClick={() => console.log("Navigating with to details for claim_id:", item.claim_id)} // Log the item
        to={`/resultviewer/${item.claim_id}`}
        state={{ item: item}}
        sx={{
          textDecoration: "none",
          "& > *": { borderBottom: "unset" },
          cursor: "pointer",
          border: 0,
        }}
        colSpan={columnsCount}
      >
        <TableCell align="center" colSpan={1} sx={{ fontSize: 16, border: 0 }}>
          <Stack>
            <Typography
              sx={{ fontSize: { xs: 14, sm: 16, md: 18 }, fontWeight: "bold" }}
            >
              {item.claim_id}
            </Typography>
            <Typography sx={{ fontSize: 12, color: "#404040" }}>
              ({item.overall_timespan} days)
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="center" colSpan={1} sx={{ border: 0 }}>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ClaimRowChip
              text={"Docs/Imgs"}
              value={current_total_docs}
              fontSize={12}
              valueSize={18}
              chip_width={"auto"}
            />
            <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
            <Stack>
              <Stack
                sx={{ alignItems: "center" }}
                spacing={0.5}
                direction={"row"}
              >
                <Typography sx={{ fontSize: 12 }}>FCM excluded:</Typography>
                <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                  {item.num_fcm_excluded}
                </Typography>
              </Stack>

              {item.num_exact_dup_excluded > -1 ? (
                <Stack
                  sx={{ alignItems: "center" }}
                  spacing={0.5}
                  direction={"row"}
                >
                  <Typography sx={{ fontSize: 12 }}>SHA excluded:</Typography>
                  <Typography sx={{ fontWeight: "bold", fontSize: 12 }}>
                    {item.num_exact_dup_excluded}
                  </Typography>
                </Stack>
              ) : null}
            </Stack>

            <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
            <ClaimRowChip
              text={"Valid Docs"}
              value={item.num_valid_doc_ids}
              fontSize={12}
              valueSize={18}
              chip_width={"auto"}
              chip_color="#8fab87"
            />
          </Stack>
        </TableCell>
        <>
          {thresholds.map(([threshold, num_tasks], index) => (
            <TableCell
              align="center"
              colSpan={1}
              sx={{
                width:80,
                py: 2,
                px: 0,
                borderBottom: 0,
                // border: 1,
                // borderLeft: index === 0 ? 0 : 1,
                borderColor: "lightgrey",
                // borderRight: index===thresholds.length ? 1: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid2 container>
                <Grid2 size="grow" />
                <Grid2>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "bold",
                      mb: 0.5,
                      // borderBottom: 1,
                    }}
                  >
                    0.{threshold}
                  </Typography>
                  {/* <KeyboardDoubleArrowDownIcon sx={{ fontSize: 20 }} /> */}
                  <ClaimRowChip
                    text={"PD Tasks"}
                    value={num_tasks}
                    fontSize={12}
                    valueSize={18}
                    chip_width={"auto"}
                    chip_color={thresholdColors[index]}
                  />

                  <Divider
                    sx={{
                      my: 0.5,
                      width: "100%",
                      // height: 0,
                      color: "lightgray",
                    }}
                  />

                  <Stack direction={"row"} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <Stack>
                    <Typography sx={{ mr: 0.5, fontSize: {xs: 8, sm:10, md:11} }}>
                      Mirror Excl:
                    </Typography>
                    </Stack>
                 
                    <Typography sx={{ fontWeight: "bold", fontSize: {xs: 8, sm:10, md:11}  }}>
                      {nm_to_all
                        ? item.num_mirror_excluded.new_mail_to_all[threshold]
                        : item.num_mirror_excluded.new_mail_to_new_mail[
                            threshold
                          ]}
                    </Typography>
                  </Stack>
                </Grid2>
                <Grid2
                  size="grow"
                  sx={{
                    display: "flex",
                    alignItems: "right",
                    justifyContent: "right",
                  }}
                >
                  {index === thresholds.length - 1 ? null : (
                    <Divider
                      flexItem
                      sx={{ color: "lightgray", height: "100%", width: 0 }}
                      orientation="vertical"
                    />
                  )}
                </Grid2>
              </Grid2>
            </TableCell>
          ))}
        </>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ p: 0, border: 0 }}
          colSpan={columnsCount}
          align="center"
        >
          {dividerText ? (
            <Divider
              textAlign="left"
              sx={{
                width: "100%",
                py: 0,
                px: 3,
                fontSize: 14,
                color: "gray",
                border: 0,
              }}
            >
              {dividerText}
            </Divider>
          ) : (
            <Divider />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default ClaimRow;
