import { Button, ButtonGroup } from '@mui/material'
import React from 'react'
import { useNewMailMode } from '../NewMailModeContext';

function NewMailModeToggle() {
const { nm_to_all, handle_new_mail_mode_change } = useNewMailMode();

  return (
    <ButtonGroup
                        orientation="vertical"
                        variant="outlined"
                        aria-label="New Mail Mode choices"
                        sx={{p:0, borderColor: "secondary.main", fontSize:12 }}
                      >
                        <Button
                          onClick={() => handle_new_mail_mode_change(false)}
                          sx={{
                            // width:170,
                            fontSize: 12,
                            height:30,
                            p:1,
                            bgcolor: nm_to_all
                              ? "background.default"
                              : "secondary.main",
                            color: nm_to_all ? "secondary.main" : "white",

                            borderColor: "secondary.main",
                          }}
                        >
                          New Mail to New Mail
                        </Button>
                        <Button
                          onClick={() => handle_new_mail_mode_change(true)}
                          sx={{
                            // width:170,
                            fontSize: 12,
                            height:30,
                            p:1,
                            bgcolor: nm_to_all
                              ? "secondary.main"
                              : "background.default",
                            color: nm_to_all ? "white" : "secondary.main",

                            borderColor: "secondary.main",
                          }}
                        >
                          New Mail to All
                        </Button>
                      </ButtonGroup>
  )
}

export default NewMailModeToggle