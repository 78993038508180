import React, {useState, useEffect} from 'react'
import {
  Box,
  Typography,
} from "@mui/material";

const SummaryPage = () => {
  return (
    <Box sx={{ p: 2, border: 0 }}>
      SUMMARY
      <Typography>
       - Total # of new mail tasks generated for all Claims by threshold
      </Typography>
      <Typography>
       - Number and % of new mail tasks that identified more than one potential duplicate across all Claims
      </Typography>
      <Typography>
       - Overall % of images across all Claims that generate a new mail task
      </Typography>
      <Typography>
       - Avg days to process potential duplicates in a Claim
      </Typography>
      <Typography>
       - Total documents excluded by FCM
      </Typography>
      <Typography>
       - Total exclusions by Auto Mirror and Fire roof model
      </Typography>
    </Box>
  );
};

export default SummaryPage;
