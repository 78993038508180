import React, { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import auto_json_95 from "../data/auto_95.json";
import auto_json_95_large from "../data/auto-results-095.json";
import auto_json_95_all from "../data/auto-results-all.json";
import dinov2results from "../data/dinov2_simulation_metrics_LOCAL2.json";
import ResultsList from "../OLD/ResultsList";
import Sorter from "../components/Sorter";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./../authConfig";
import SimResultsList from "../components/SimResultsList";

const AutoListPage = () => {
  const [results, setResults] = React.useState(null);
  const { instance, accounts } = useMsal();
  const [bearerToken, setBearerToken] = useState("");
  // useEffect(() => {
  //   const accessTokenRequest = {
  //     scopes: loginRequest.scopes,
  //     account: accounts[0],
  //   };

  //   instance
  //     .acquireTokenSilent(accessTokenRequest)
  //     .then((accessTokenResponse) => {
  //       setBearerToken(accessTokenResponse.accessToken);
  //     });
  // });

  const [autoData, setAutoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // setResults(dinov2results.auto)
  useEffect(() => {
    fetch("https://zqhmgl85wh.execute-api.us-east-1.amazonaws.com/results/dinov2results")
      .then((response) => response.json())
      .then((data) => {
        setResults(data.auto);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Box sx={{ p: 2, border: 0 }}>
      <SimResultsList type_of_claim={"Auto"} data={results}/>
      {/* <Stack
        sx={{
          //display: "flex",
          alignItems: "center",
          //   justifyContent: "center",
        }}
        direction={"row"}
        spacing={2}
      >
        <Typography sx={{ fontSize: 24 }}>Auto Results</Typography>
        <Typography sx={{ fontSize: 16 }}>
          {auto_json_95_all.results.length} Claims Found
        </Typography>

        <Sorter
          results={results}
          setResults={setResults}
          thresholds={auto_json_95_all.thresholds}
        />
      </Stack>
      <ResultsList data={results} /> */}
    </Box>
  );
};

export default AutoListPage;
