import {
  Box,
  Button,
  IconButton,
  ImageListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PDImage from "./PDImage";
import ClearIcon from "@mui/icons-material/Clear";

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxHeight: "90%",
  maxWidth: "1600px",
  bgcolor: "background.default",
  border: "1px solid #000",
  borderRadius: 5,
  boxShadow: 10,
  p: 3,
  mt: 2,
};

function PDImageModal({ task_index, img1, img2, num_pages = 1 }) {
  const [openImage, setOpenImage] = React.useState(false);
  const [selectedImages, setSelectedImages] = React.useState(null);
  const [task_num, setTaskNum] = React.useState(null);

  const handleImageClick = (imageSrc1, imgSrc2, selected_task_num) => {
    setSelectedImages([imageSrc1, imgSrc2]);
    setOpenImage(true);
    setTaskNum(selected_task_num);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
    setSelectedImages(null);
    setTaskNum(null);
  };

  return (
    <React.Fragment>
      <ImageListItem
        onClick={() =>
          handleImageClick(
            `${process.env.PUBLIC_URL}/images/${img1}`,
            `${process.env.PUBLIC_URL}/images/${img2}`,
            task_index + 1
          )
        }
        sx={{
          border: 1,
          borderColor: "lightgray",
          borderRadius: 1,
          p: 1,
          m: 1,
        }}
      >
        <Typography sx={{ pb: 1, fontSize: 14, fontWeight: "bold" }}>
          PD Task: {task_index+1}
        </Typography>
        <PDImage
          imgSrc1={img1}
          imgSrc2={img2}
          task_index={task_index + 1}
        />
      </ImageListItem>
      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="enlarged-image-modal-description"
      >
        <Box sx={imageModalStyle}>
          <Stack direction={"row"} sx={{pb:2, alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={handleCloseImage}
              sx={{
                borderRadius: 3,
                textTransform: "unset",
                backgroundColor: "secondary.main",
              }}
              startIcon={<ClearIcon />}
            >
              CLOSE
            </Button>
            <Typography
              sx={{ p: 0, pb: 0, ml: 2, fontSize: 16, fontWeight: "bold" }}
            >
              PD Task: {task_num}
            </Typography>
          </Stack>
          {selectedImages && (
            <PDImage
              imgSrc1={img1}
              imgSrc2={img2}
              task_index={task_index + 1}
            />
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default PDImageModal;
