import "./App.css";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import AutoListPage from "./pages/AutoListPage.js";
import FireListPage from "./pages/FireListPage.js";
import { appTheme } from "./theme.ts";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import SummaryPage from "./pages/SummaryPage.js";
import DetailsPage from "./pages/DetailsPage.js";
import { NewMailModeProvider } from "./NewMailModeContext.js";

const headerHeight = 65;
const drawerWidth = 175;

function App() {
  function ErrorComponent({error}) {
    return <p>An Error Occurred: {JSON.stringify(error)}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}
  return (
    <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={loginRequest} 
          errorComponent={ErrorComponent} 
          loadingComponent={LoadingComponent}
        >
    <div>
    <NewMailModeProvider>
    <BrowserRouter>
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Header header_height={headerHeight} drawer_width={drawerWidth}/>
      <Box sx={{ml: {xs:0, md: `${drawerWidth}px` }, mt: `${headerHeight}px`}}>

     
        <Routes>
          <Route path="/" element={<AutoListPage/>} />
          <Route path="/fire" element={<FireListPage />} />
          <Route path="/summary" element={<SummaryPage />} />
          <Route path="/resultviewer/:claim_id" element={<DetailsPage />} />
        </Routes>
        </Box>
    </ThemeProvider>
    </BrowserRouter>
    </NewMailModeProvider>
    </div>
    </MsalAuthenticationTemplate>
  );
}

export default App;
