import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function DrawerButton({closeDrawer, unique_key, icon, path_to, text }) {
  const location = useLocation();

  return (
    <ListItem key={unique_key} disablePadding>
      <ListItemButton
      onClick={closeDrawer}
        sx={{
          borderRadius: 5,
          bgcolor:
            location.pathname === path_to
              ? "secondary.main"
              : "background.default",
          "&:hover": {
            backgroundColor: "grey",
            color: location.pathname === path_to ? "white" : "secondary.main",
          },
          color:
            location.pathname === path_to
              ? "background.default"
              : "secondary.main",
        }}
        component={Link}
        to={path_to}
      >
        <ListItemIcon
        
          sx={{
            p:0,
            m:0,
            color: location.pathname === path_to ? "white" : "secondary.main",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

export default DrawerButton;
