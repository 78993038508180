import * as React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CustomChip from "../OLD/CustomChip";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 2,
  boxShadow: 10,
  p: 4,
  mt: 4,
};

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "1150px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 2,
  boxShadow: 10,
  p: 4,
  mt: 4,
};

function TasksDisplayer({
  claim_id,
  tasks_list,
  num_docs,
  date,
  setCloseTasks,
  threshold,
  threshold_color,
}) {
  const id_color = "#3c3c3c";
  const chip_color = "#e1e1e1";

  const [openImage, setOpenImage] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [task_num, setTaskNum] = React.useState(null);

  const handleImageClick = (imageSrc, selected_task_num) => {
    setSelectedImage(imageSrc);
    setOpenImage(true);
    setTaskNum(selected_task_num);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
    setSelectedImage(null);
    setTaskNum(null);
  };

  const num_tasks = tasks_list.length;
  return (
    <Box sx={style}>
      <Stack spacing={2} direction={"row"} sx={{ alignItems: "center" }}>
        <IconButton
          aria-label="close-tasks"
          onClick={setCloseTasks}
          sx={{ mr: 1, p: 0, color: "red" }}
        >
          <CloseIcon sx={{ width: 30, height: 30 }} />
        </IconButton>
        <Typography sx={{ fontWeight: "bold", fontSize: 20, mt: 1 }}>
          {claim_id}
        </Typography>
        <Typography sx={{ fontSize: 20, mt: 1 }}>{date}</Typography>
        <CustomChip text={"Docs: " + num_docs} />
        <CustomChip text={"PD Tasks: " + num_tasks} chip_color={threshold_color} chip_width={120} />
        <Typography sx={{ fontSize: 14, mt: 1 }}>
          Similarity Threshold:{" "}
          {threshold.substring(0, 1) + "." + threshold.substring(1)}
        </Typography>
      </Stack>
      <Divider sx={{ pt: 2 }} />
      <ImageList
        sx={{ p: 0, width: "100%", height: 600 }}
        cols={3}
        rowHeight={175}
      >
        {tasks_list.map((item, index) => (
          <ImageListItem
            onClick={() =>
              handleImageClick(
                `${process.env.PUBLIC_URL}/images/${item}`,
                index + 1
              )
            }
            sx={{ border: 0, borderColor: "lightgrey", px: 1, m: 1 }}
            key={index + 1}
          >
            <Typography sx={{ p: 0, m: 0 }}>PD Task: {index + 1} </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/images/${item}`}
              alt={`task-${index + 1}`}
              loading="lazy"
              style={{
                width: "100%",
                // height: '100%',
                objectFit: "contain",
                cursor: "pointer",
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="enlarged-image-modal-description"
      >
        <Box sx={imageModalStyle}>
          <Stack direction={"row"} sx={{ alignItems: "center" }}>
            <IconButton
              aria-label="close"
              onClick={handleCloseImage}
              // sx={{ mr:1, mb:1, color: "red" }}
              sx={{ mr: 1, p: 0, pb:1, color: "red" }}
            >
              <CloseIcon sx={{ width: 30, height: 30 }} />
            </IconButton>
            <Typography sx={{ p: 0, pb: 1, m: 0, fontSize: 16 }}>
              PD Task: {task_num}
            </Typography>
          </Stack>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Enlarged view"
              style={{
                width: "100%",
                height: "auto",
                display: "block",
                margin: "0 auto",
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default TasksDisplayer;
