import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";

import { msalConfig } from "./authConfig";

const pca = new PublicClientApplication(msalConfig);

const AppWithAuthentication = () => (
  <MsalProvider instance={pca}>
      <App />
  </MsalProvider>
);

ReactDOM.render(<AppWithAuthentication />, document.getElementById("root"));
