import {
  Box,
  Button,
  Divider,
  Grid2,
  Modal,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import ClaimRowChip from "./ClaimRowChip";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";
import PDListDisplayer from "./PDListDisplayer";

const thresholdColors = [
  "#b6d8e3",
  "#a9d6e5", //lightest blue
  "#89c2d9", //
  "#61a5c2",
  "#468faf",
  "#2c7da0"
];

function prettyDate(dateString) {
  // Split the input date string into its components
  const [year, month, day] = dateString.split("-").map(Number);

  // Create a new Date object using the extracted components
  const date = new Date(year, month - 1, day);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dayOfMonth = date.getDate();
  const monthName = monthNames[date.getMonth()];
  const yearNumber = date.getFullYear();

  // Function to get the ordinal suffix
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // covers 11th to 20th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const dayWithSuffix = dayOfMonth + getOrdinalSuffix(dayOfMonth);

  return `${monthName} ${dayWithSuffix}, ${yearNumber}`;
}

const processDayData = (dayEntries, nm_to_all) => {
  const processedDayData = {};

  dayEntries.forEach(([date, details]) => {
    processedDayData[date] = {
      num_excluded: details.num_excluded,
      num_valid_docs: details.num_valid_docs,
      num_images_from_valid_docs: details.num_images_from_valid_docs,
      task_list_for_thresholds: nm_to_all
        ? details.task_list_for_thresholds.new_mail_to_all
        : details.task_list_for_thresholds.new_mail_to_new_mail,
    };
  });

  return processedDayData;
};

function ClaimDetailsByDay({
  details_by_day,
  thresholds,
  claim_id,
  nm_to_all,
}) {
  const dayEntries = Object.entries(details_by_day);

  const processedDayData = processDayData(dayEntries, nm_to_all);
  
  

  function hasTasks(taskList) {
    for (let i = 0; i < thresholds.length; i++) {
      const threshold = thresholds[i][0]; // Access the first element of each sub-array
      if (taskList[threshold] && taskList[threshold].length > 0) {
        return true;
      }
    }
    return false;
  }

  function hasValidDocsForDate(date_info) {
    return date_info.num_valid_docs > 0;
  }

  const [openStates, setOpenStates] = React.useState(
    dayEntries.map(() => false)
  );
  const handleOpenPDs = (date_index) => {
    setOpenStates({ ...openStates, [date_index]: true });
  };

  const handleClosePDs = (date_index) => {
    setOpenStates({ ...openStates, [date_index]: false });
  };
  return (
    <Box sx={{ width: "100%", justifyContent: "left", display: "flex" }}>
      <Table sx={{ mx: 3, my: 2, width: "auto" }}>
        <TableHead>
          <TableRow sx={{ borderBottom: 2, borderColor: "lightgray" }}>
            <TableCell sx={{ p: 0, border: 0 }} align="center">
              <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>
                Date
              </Typography>
            </TableCell>
            <TableCell sx={{ p: 0, border: 0 }} />
            <TableCell
              sx={{ p: 0, border: 0, fontSize: 14, fontWeight: "bold" }}
              align="center"
            >
              Valid Doc IDs
            </TableCell>
            <TableCell sx={{ p: 0, border: 0 }} />
            {thresholds.map(([threshold], index) => (
              <TableCell sx={{bgcolor: thresholdColors[index], p: 0, borderRight: 3, borderLeft:3, borderColor: "background.default", }} align="center">
                <Typography
                  sx={{
                    fontSize: 14,
                    bgcolor: thresholdColors[index],
                    fontWeight: "bold",
                  }}
                >
                  0.{threshold}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {Object.entries(processedDayData).map(([date, date_info], date_index) => (
          <React.Fragment key={date_index}>
            {hasValidDocsForDate(date_info) ? (
              <TableRow sx={{ borderBottom: 2, borderColor: "lightgray" }}>
                <TableCell sx={{ border: 0, p: 2 }} align="center">
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 13, md: 14 },
                      fontWeight: "light",
                    }}
                  >
                    {prettyDate(date)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0, p: 0 }} align="center">
                  <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                </TableCell>
                <TableCell sx={{ p: 1, border: 0 }} align="center">
                  <Typography
                    sx={{
                      fontSize: { xs: 12, sm: 13, md: 14 },
                      fontWeight: "light",
                      color: green[900],
                    }}
                  >
                    {date_info.num_valid_docs} Valid Doc IDs
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: 0, p: 0, pr: 1 }} align="center">
                  <KeyboardDoubleArrowRightIcon sx={{ fontSize: 20 }} />
                </TableCell>

                <>
                  {Object.entries(date_info.task_list_for_thresholds)
                  .map(([threshold, tasks_list], index) => (
                    <TableCell
                    key={index}
                      align="center"
                      colSpan={1}
                      sx={{
                        borderRight: 3, borderLeft:3, borderColor: "background.default",
                        py: 0,
                        px: 1,
                        borderBottom: 0,
                        backgroundColor: thresholdColors[index],
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ClaimRowChip
                        text={"PD Tasks"}
                        value={tasks_list.length}
                        fontSize={12}
                        valueSize={18}
                        chip_width={"auto"}
                        chip_color={thresholdColors[index]}
                      />
                    </TableCell>
                  ))}
                </>
                {hasTasks(
                  date_info.task_list_for_thresholds
                ) ? (
                  <TableCell sx={{ p: 0, px: 1 }} align="center">
                    <Button
                      onClick={() => handleOpenPDs(date_index)}
                      startIcon={<ViewCompactIcon />}
                      variant="contained"
                      sx={{ backgroundColor: "secondary.main", fontSize: {xs: 12, md:14} }}
                    >
                      View PDs
                    </Button>
                  </TableCell>
                ) : (
                  <TableCell
                    align="center"
                    sx={{ fontSize: 12, color: "gray" }}
                  >
                    0 PDs to view{" "}
                  </TableCell>
                )}
              </TableRow>
            ) : null}
            <Modal
              open={openStates[date_index] || false}
              onClose={() => handleClosePDs(date_index)}
              aria-labelledby="img-modal"
              aria-describedby="displays PDs"

              sx={{
                ml: { xs: 0, md: "175px" },
                width: "100%",
                display: "flex",
                justifyContent: "left",
                overflow:'scroll',

              }}
            >
              <PDListDisplayer
                claim_id={claim_id}
                tasks_info={
                  date_info.task_list_for_thresholds
                }
                num_valid_docs={date_info.num_valid_docs}
                date={prettyDate(date)}
                setClosePDTasks={() => handleClosePDs(date_index)}
                threshold_colors={thresholdColors}
              />
            </Modal>
          </React.Fragment>
        ))}
      </Table>
    </Box>
  );
}

export default ClaimDetailsByDay;
